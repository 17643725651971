@font-face {
    font-family: 'PP Neue Montreal';
    src: url('/assets/fonts/PPNeueMontreal-Medium.eot');
    src: url('/assets/fonts/PPNeueMontreal-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PPNeueMontreal-Medium.woff2') format('woff2'),
        url('/assets/fonts/PPNeueMontreal-Medium.woff') format('woff'),
        url('/assets/fonts/PPNeueMontreal-Medium.ttf') format('truetype'),
        url('/assets/fonts/PPNeueMontreal-Medium.svg#PPNeueMontreal-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('/assets/fonts/PPNeueMontreal-Bold.eot');
    src: url('/assets/fonts/PPNeueMontreal-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PPNeueMontreal-Bold.woff2') format('woff2'),
        url('/assets/fonts/PPNeueMontreal-Bold.woff') format('woff'),
        url('/assets/fonts/PPNeueMontreal-Bold.ttf') format('truetype'),
        url('/assets/fonts/PPNeueMontreal-Bold.svg#PPNeueMontreal-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Neue Montreal Book';
    src: url('/assets/fonts/PPNeueMontreal-Book.eot');
    src: url('/assets/fonts/PPNeueMontreal-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PPNeueMontreal-Book.woff2') format('woff2'),
        url('/assets/fonts/PPNeueMontreal-Book.woff') format('woff'),
        url('/assets/fonts/PPNeueMontreal-Book.ttf') format('truetype'),
        url('/assets/fonts/PPNeueMontreal-Book.svg#PPNeueMontreal-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('/assets/fonts/PPNeueMontreal-Light.eot');
    src: url('/assets/fonts/PPNeueMontreal-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PPNeueMontreal-Light.woff2') format('woff2'),
        url('/assets/fonts/PPNeueMontreal-Light.woff') format('woff'),
        url('/assets/fonts/PPNeueMontreal-Light.ttf') format('truetype'),
        url('/assets/fonts/PPNeueMontreal-Light.svg#PPNeueMontreal-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('/assets/fonts/PPNeueMontreal-Thin.eot');
    src: url('/assets/fonts/PPNeueMontreal-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PPNeueMontreal-Thin.woff2') format('woff2'),
        url('/assets/fonts/PPNeueMontreal-Thin.woff') format('woff'),
        url('/assets/fonts/PPNeueMontreal-Thin.ttf') format('truetype'),
        url('/assets/fonts/PPNeueMontreal-Thin.svg#PPNeueMontreal-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('/assets/fonts/PPNeueMontreal-Regular.eot');
    src: url('/assets/fonts/PPNeueMontreal-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
        url('/assets/fonts/PPNeueMontreal-Regular.woff') format('woff'),
        url('/assets/fonts/PPNeueMontreal-Regular.ttf') format('truetype'),
        url('/assets/fonts/PPNeueMontreal-Regular.svg#PPNeueMontreal-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
